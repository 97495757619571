"use client";

import GoogleAuthButton from "@/components/auth/GoogleAuthButton";
import { Button, buttonVariants } from "@/components/ui/Button";
import { Checkbox } from "@/components/ui/Checkbox";
import ErrorMessage from "@/components/ui/ErrorMessage";
import Input from "@/components/ui/Input";
import { Separator } from "@/components/ui/Separator";
import usePost from "@/hooks/usePost";
import { loginSchema } from "@/schemas/auth/login";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import useStore from "store";
import { isUserLocalTimeCorrect } from "utils/isUserLocalTimeCorrect";
import RegisterWithQRCodeDialog from "./RegisterWithQRCodeDialog";

const LoginForm = () => {
  const signIn = useStore(state => state.signIn);
  const router = useRouter();
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const isAccountActivate = useStore(state => state.isAccountActivate);
  const isPhoneVerified = useStore(state => state.isPhoneVerified);
  const isPinCreated = useStore(state => state.isPinCreated);
  const { handlePost, error, isLoading } = usePost(handleLogin);
  const { t } = useTranslation("login");

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      rememberMe: true,
    },
  });

  async function isUserLocalTimeCorrectWithTimeout(timeoutMs = 4000) {
    // Create a timeout promise
    const timeoutPromise = new Promise(resolve => {
      setTimeout(() => {
        resolve(true); // Resolve with true if the timeout is reached
      }, timeoutMs);
    });

    // Create the actual API request promise
    const apiRequestPromise = isUserLocalTimeCorrect();

    // Race the API request promise against the timeout promise
    return await Promise.race([apiRequestPromise, timeoutPromise]);
  }

  async function handleLogin(values) {
    const isLocalTimeCorrect = await isUserLocalTimeCorrectWithTimeout();
    if (!isLocalTimeCorrect) {
      toast.error(t("incorrect_device_time"));
      return;
    }

    await signIn(values);
  }

  useEffect(() => {
    if (isLoggedIn) {
      if (isAccountActivate && isPhoneVerified && isPinCreated) {
        window.location.href = "/dashboard";
      } else {
        window.location.href = "/emailNotVerified";
      }
    }
  }, [isLoggedIn, isAccountActivate, isPhoneVerified, isPinCreated, router]);

  return (
    <form
      onSubmit={handleSubmit(handlePost)}
      className="w-[500px] max-w-full lg:mx-6 grid gap-4 mt-8 xl:mb-16"
    >
      <ErrorMessage error={error} />

      <label className="block">
        <span className="block font-medium">{t("username")}</span>
        <Input
          {...register("username")}
          type="text"
          errorMessage={t(errors.username?.message)}
          placeholder={t("placeholders.username")}
          className="!bg-background"
          autoFocus
        />
      </label>

      <div>
        <label className="block">
          <span className="block font-medium"> {t("password")}</span>
          <Input
            {...register("password")}
            errorMessage={t(errors.password?.message)}
            type="password"
            placeholder={t("placeholders.password")}
          />
        </label>

        <div className="flex items-center justify-between">
          <label className="flex items-center gap-1">
            <Checkbox
              onCheckedChange={event => setValue("rememberMe", event)}
              defaultChecked={true}
            />
            <span className="text-muted-foreground">{t("rememberMe")}</span>
          </label>

          <Link href="/forgot">
            <a
              className={buttonVariants({
                variant: "link",
                className: "!px-0",
              })}
            >
              {t("forgotPassword")}
            </a>
          </Link>
        </div>
      </div>

      <Button isLoading={isLoading} type="submit" className="w-full">
        {t("loginButton")}
      </Button>

      <div className="flex items-center gap-2">
        <Separator />
        <span className="text-muted-foreground">{t("or")}</span>
        <Separator />
      </div>

      <div className="flex flex-col gap-2">
        <GoogleAuthButton isLogin />
        <RegisterWithQRCodeDialog />
      </div>

      <span className="font-medium text-muted-foreground text-center">
        {t("noAccount")}
        <Link href="/register" passHref>
          <Button variant="link" className="p-0 font-medium text-base">
            &nbsp;{t("here")}
          </Button>
        </Link>
      </span>
    </form>
  );
};

export default LoginForm;

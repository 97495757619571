import { cn } from "@/lib/utils";
import React from "react";

const ErrorMessage = ({ error, className }) => {
  return (
    <div
      className={cn(
        "hidden w-full py-2 px-4 rounded-md bg-destructive text-destructive-foreground mb-3",
        className,
        error && "flex"
      )}
    >
      <span>{error}</span>
    </div>
  );
};

export default ErrorMessage;

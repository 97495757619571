// schema/login.js
import * as yup from "yup";

export const loginSchema = yup
  .object()
  .shape({
    username: yup
      .string()
      .required("schema.required_username")
      .max(60, "schema.max_username"),
    password: yup
      .string()
      .required("schema.required_password")
      .min(8, "schema.password_min")
      .max(60, "schema.password_max"),
  })
  .required();

import axios from "axios";

export const isUserLocalTimeCorrect = async () => {
  try {
    const clientTimezone = "Africa/Algiers";

    // Get current time in the user's timezone
    const currentTimeResponse = await axios.get(
      `https://worldtimeapi.org/api/timezone/${clientTimezone}`
    );
    const currentWorldTime = new Date(currentTimeResponse.data.utc_datetime);

    const timeDiff = currentWorldTime.getTime() - Date.now();

    const ONE_HOUR_IN_MILLISECONDS = 3600 * 1000;

    return Math.abs(timeDiff) <= ONE_HOUR_IN_MILLISECONDS;
  } catch (error) {
    return true;
  }
};

"use client";

import { useRouter } from "next/router";

const useGoogleAuth = () => {
  const router = useRouter();
  const { referralLink } = router.query;
  const API_URL = process.env.NEXT_PUBLIC_API?.slice(0, -7);

  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  const redirectUri = origin + "/oauth2/redirect";

  const googleAuthUrl =
    API_URL +
    "/oauth2/authorization/google" +
    "?redirectUri=" +
    redirectUri +
    (referralLink ? `&superId=${referralLink}` : "");

  return { googleAuthUrl, referralLink };
};

export default useGoogleAuth;



import { buttonVariants } from "@/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/Dialog";
import { useRouter } from "next/router";
import QrScanner from "qr-scanner";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoQrCode } from "react-icons/io5";
import { LuScanLine } from "react-icons/lu";

const QRCodeScanner = () => {
  const [scanner, setScanner] = useState(null);
  const [referralLink, setReferralLink] = useState("");
  const router = useRouter();
  const videoRef = useRef(null);

  useEffect(() => {
    if (referralLink) {
      router.push(referralLink);
    }
  }, [referralLink, router]);

  useEffect(() => {
    if (videoRef.current && !scanner) {
      const qrScanner = new QrScanner(
        videoRef.current,
        result => {
          if (result?.data) {
            setReferralLink(result.data);
          }
        },
        {
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );
      setScanner(qrScanner);
    }

    return () => {
      if (scanner) {
        scanner.stop();
      }
    };
  }, [videoRef, scanner, router]);

  useEffect(() => {
    if (scanner) {
      scanner.start();
    }
  }, [scanner]);

  return <video ref={videoRef} className="w-full border rounded" />;
};

const RegisterWithQRCodeDialog = () => {
  const { t } = useTranslation("login");
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className={buttonVariants({
          className: "w-full",
          variant: "outline",
        })}
      >
        <IoQrCode className="text-[1rem]" />
        <span className="text-sm">{t("register_via_qr_code")}</span>
      </DialogTrigger>

      <DialogContent className="gap-0 p-1 text-foreground z-[99]">
        <div className="gradient py-6 bg-gradient-to-b from-primary/25 to-background to-70% rounded-md grid place-items-center">
          <div className="w-[70px] h-[70px] grid place-items-center bg-primary/20 rounded-full mx-auto border-2 border-background">
            <LuScanLine className="h-[40px] w-[40px] text-primary" />
          </div>

          <DialogHeader className="h-fit mt-4">
            <DialogTitle className="text-center">
              {t("scan_qr_code")}
            </DialogTitle>
          </DialogHeader>
        </div>

        <QRCodeScanner />
      </DialogContent>
    </Dialog>
  );
};

export default RegisterWithQRCodeDialog;
